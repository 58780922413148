import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangeStatusInput } from "@shared/models/research";
import { ResearchFilters } from "@shared/models/research";
import { ResearchesResponse } from "@shared/models/research";
import { Research, ResearchInput } from '@shared/models/research';
import { Status } from '@shared/models/status';
import { environment } from 'src/environments/environment';
import { getHttpParams } from "@shared/helpers/headers";
import { getHeadersOption } from "@shared/helpers/headers";

@Injectable({
  providedIn: 'root'
})
export class ResearchService {

  constructor(private http: HttpClient) {
  }

  get(filters: ResearchFilters, customFilter: string = ''): Observable<ResearchesResponse> {
    const newFilters = {...filters}
    if (newFilters.hasOwnProperty("serviceIds")) {
      customFilter = customFilter + (newFilters.serviceIds ? `serviceIds=${newFilters.serviceIds}` : '');
      delete newFilters.serviceIds;
    }
    if (newFilters.hasOwnProperty("contragentId")) {
      customFilter = customFilter + (customFilter.length ? '&' : '') + (newFilters.contragentId ? `contragentId=${newFilters.contragentId}` : '');
      delete newFilters.contragentId;
    }//TODO: Переделать на нормальное
    return this.http.get<ResearchesResponse>(`${environment.apiUrl}/api/researches?${customFilter}`, {
      headers: getHeadersOption(),
      params: getHttpParams(newFilters)
    });
  }

  getExcel(filters: ResearchFilters, customFilter?: string): Observable<Blob> {
    const keysToExclude = ["limit", "offset", "orderBy"];

    const filteredObject = Object.fromEntries(
      Object.entries(filters).filter(([key]) => !keysToExclude.includes(key))
    );

    const url = (customFilter && customFilter !== 'serviceIds=') ? `${environment.apiUrl}/api/researches/excel?${customFilter}` : `${environment.apiUrl}/api/researches/excel`;

    return this.http.get(url, {
      responseType: 'blob',
      headers: getHeadersOption(),
      params: getHttpParams(filteredObject)
    });
  }

  getById(id: number): Observable<Research> {
    return this.http.get<Research>(environment.apiUrl + `/api/researches/${id}`, {
      headers: getHeadersOption()
    });
  }

  create(data: ResearchInput): Observable<Research> {
    return this.http.post<Research>(environment.apiUrl + '/api/researches', data, {
      headers: getHeadersOption()
    });
  }

  update(data: ResearchInput): Observable<Research> {
    return this.http.patch<Research>(environment.apiUrl + '/api/researches', data, {
      headers: getHeadersOption()
    });
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/researches/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restore(id: number): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/api/researches/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }

  getStatuses(): Observable<Status[]> {
    return this.http.get<Status[]>(environment.apiUrl + '/api/researches/statuses', {
      headers: getHeadersOption()
    });
  }

  changeStatuses(data: ChangeStatusInput): Observable<Research[]> {
    return this.http.patch<Research[]>(environment.apiUrl + `/api/researches/status?statusId=${data.statusId}`, {ids: data.ids}, {
      headers: getHeadersOption(),
    });
  }

  result(data: { id: number, result: string }[]): Observable<Research[]> {
    return this.http.patch<Research[]>(environment.apiUrl + `/api/researches/results`, data, {
      headers: getHeadersOption(),
    });
  }
}
