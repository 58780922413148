import { Component, Input, SimpleChanges } from '@angular/core';
import localeRu from '@angular/common/locales/ru';
import { CommonModule, registerLocaleData } from '@angular/common';
import {
  FormsModule,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ClarityModule,
  ClrComboboxModule,
  ClrCommonFormsModule,
  ClrIconModule,
  ClrModalModule,
} from '@clr/angular';

import {
  Observable,
  debounceTime,
  switchMap,
  Subject,
  takeUntil,
  catchError,
  EMPTY,
  finalize,
  map,
  tap,
} from 'rxjs';

import { Status } from '@shared/models/status';
import { Entity } from '@shared/models/entity';
import { Filter } from '@shared/models/filter';
import { Document } from '@shared/models/document';
import { renderDoctorName } from '@shared/models/doctor';
import { User, UsersResponse } from '@shared/models/user';
import {
  Sample,
  SamplesResponse,
  renderSampleName,
} from '@shared/models/sample';
import {
  Animal,
  AnimalsResponse,
  renderParentName,
} from '@shared/models/animal';
import {
  Service,
  ServicesResponse,
  renderServiceName,
} from '@shared/models/service';
import {
  Research,
  ResearchInput,
  defaultResearchStatus,
} from '@shared/models/research';
import {
  Order,
  OrderType,
  OrderTypes,
  OrdersResponse,
  renderAnimalName,
  renderOrderName,
} from '@shared/models/order';
import {
  CounterpartiesFilters,
  CounterpartiesResponse,
  Counterparty,
} from '@shared/models/counterparty';

import { convertFormat, dateValidator } from '@shared/helpers/date';

import { SampleComponent } from '@modules/samples/components/sample/sample.component';
import { AnimalComponent } from '@modules/admin/components/registers/animals/animal/animal.component';

import { DocumentService } from '@core/services/document.service';

import { ButtonComponent } from '@core/components/button/button.component';
import { SelectComponent } from '@core/components/input/select/select.component';
import { UploadDocumentComponent } from '@core/components/documents/document.component';
import { TextFieldComponent } from '@core/components/input/text-field/text-field.component';
import { DatepickerComponent } from '@core/components/input/date-picker/datepicker.component';
import { AutocompleteComponent } from '@core/components/input/autocomplete/autocomplete.component';
import { MultipleAutocompleteComponent } from '@core/components/input/multiple-autocomplete/multiple-autocomplete.component';
import { DataService } from '@core/services/data.service';
import { ResearchService } from '@modules/researches/services/research.service';
import {currencies, Currency, defaultCurrency} from "@shared/models/currency";

registerLocaleData(localeRu);

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    ClrIconModule,
    ClarityModule,
    ClrModalModule,
    SelectComponent,
    ButtonComponent,
    ClrComboboxModule,
    TextFieldComponent,
    DatepickerComponent,
    ReactiveFormsModule,
    ClrCommonFormsModule,
    AutocompleteComponent,
    UploadDocumentComponent,
    MultipleAutocompleteComponent,

    SampleComponent,
    AnimalComponent,
  ],
  standalone: true,
})
export class ResearchComponent {
  @Input() selectOrder: Order | null;
  @Input() researches: Research[];
  @Input() handleClose: () => void;
  @Input() addOrUpdateResearch?: (research: Research) => void;
  @Input() addOrUpdateItem?: (
    researches: Research[],
    research: Research
  ) => void;
  @Input() selectResearch: Research | null;

  protected readonly OrderTypes = OrderTypes;
  protected readonly currencies = currencies;
  protected readonly renderOrderName = renderOrderName;
  protected readonly renderSampleName = renderSampleName;
  protected readonly renderDoctorName = renderDoctorName;
  protected readonly renderAnimalName = renderAnimalName;
  protected readonly renderParentName = renderParentName;
  protected readonly renderServiceName = renderServiceName;

  private searchOrderSubject$ = new Subject<string>();
  private searchDoctorSubject$ = new Subject<string>();
  private searchSampleSubject$ = new Subject<string>();
  private searchServiceSubject$ = new Subject<string>();
  private searchMothersSubject$ = new Subject<string>();
  private searchFathersSubject$ = new Subject<string>();
  private searchSubcontractorsSubject$ = new Subject<string>();

  private filesChange$ = new Subject<Document[]>();
  private formDataChange$ = new Subject<FormData>();
  private entityChange$ = new Subject<Entity | undefined>();

  private saveButtonClick$ = new Subject<void>();
  private closeButtonClick$ = new Subject<void>();

  private createSampleEvent$ = new Subject<Sample>();

  private closeAnimalModal$ = new Subject<void>();
  private updateAnimalEvent$ = new Subject<Animal>();
  private openAnimalModal$ = new Subject<'mothers' | 'fathers'>();
  private createAnimalEvent$ = new Subject<{ animal: Animal, input: 'mothers' | 'fathers' }>();

  private readonly destroy$ = new Subject<void>();

  isOpen: boolean = true;
  loading: boolean = false;
  isFormInitialized = false;
  isOpenSampleModal: boolean = false;

  form: FormGroup;
  entity: Entity | undefined;
  fileInput: HTMLInputElement;
  formData: FormData = new FormData();

  research: Research | null;
  researchId: number | undefined;

  orders: Order[];
  orderType: OrderType | null;

  files: Document[];

  samples: Sample[];
  sample: Sample | null;

  animal: Animal;
  mothers: Animal[];
  fathers: Animal[];
  currentAnimalInput: 'mothers' | 'fathers';
  isOpenAnimalModal: boolean = false;

  statuses: Status[];
  entities: Entity[];
  services: Service[];

  doctors: User[];
  subcontractors: Counterparty[];

  filters: Filter = {
    isDeleted: false,
    limit: 10,
  }

  filtersWithTypeId: Filter = {
    isDeleted: false,
    limit: 10
  }

  subcontractorsFilters: CounterpartiesFilters = {
    limit: 10,
    isDeleted: false,
    isPerformer: true
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectResearch']) {
      this.researchId = this.selectResearch?.id;
    }
  }

  onFilesChange(files: Document[]): void {
    this.filesChange$.next(files);
  }

  onFormDataChange(formData: FormData): void {
    this.formDataChange$.next(formData);
  }

  onEntityChange(entity: Entity | undefined): void {
    this.entityChange$.next(entity);
  }

  onSearchOrder = (input: string) => this.searchOrderSubject$.next(input);
  onSearchDoctor = (input: string) => this.searchDoctorSubject$.next(input);
  onSearchSample = (input: string) => this.searchSampleSubject$.next(input);
  onSearchMother = (input: string) => this.searchMothersSubject$.next(input);
  onSearchFather = (input: string) => this.searchFathersSubject$.next(input);
  onSearchService = (input: string) => this.searchServiceSubject$.next(input);
  onSearchPerformer = (input: string) => this.searchSubcontractorsSubject$.next(input);


  constructor(
    private documentService: DocumentService,
    private researchService: ResearchService,
    private dataService: DataService,
  ) {}

  ngOnInit() {
    this.initializeData();
    this.filesChange$
    .pipe(
      tap(files => this.files = files)
    )
    .subscribe();

  this.formDataChange$
    .pipe(
      tap(formData => this.formData = formData)
    )
    .subscribe();

  this.entityChange$
    .pipe(
      tap(entity => this.entity = entity)
    )
    .subscribe();

    this.saveButtonClick$
    .pipe(
      switchMap(() => this.handleSaveButtonLogic()),
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    )
    .subscribe();

  this.closeButtonClick$
    .pipe(
      tap(() => {
        this.handleClose();
        this.research = null;
        this.researchId = undefined;
        this.isOpen = false;
      })
    )
    .subscribe();

    this.createSampleEvent$
    .pipe(
      tap(data => this.form.get('sample')?.setValue(data))
    )
    .subscribe();

    this.openAnimalModal$
    .pipe(
      tap(input => {
        this.currentAnimalInput = input;
        this.isOpenAnimalModal = true;
      })
    )
    .subscribe();

  this.closeAnimalModal$
    .pipe(
      tap(() => this.isOpenAnimalModal = false)
    )
    .subscribe();

  this.createAnimalEvent$
    .pipe(
      tap(event => {
        if (event) {
          const control = this.form.get(event.input);
          const currentValues = control?.value ?? [];
          control?.setValue([...currentValues, event.animal]);
        }
      })
    )
    .subscribe();

  this.updateAnimalEvent$
    .pipe(
      tap(animal => {
        const targetArray = (animal?.gender.toLowerCase() === 'female') ? this.mothers : this.fathers;
        const index: number = targetArray.findIndex((item: Animal): boolean => item.id === animal.id);

        if (index === -1) {
          targetArray.unshift(animal);
        } else {
          targetArray[index] = animal;
        }
      })
    )
    .subscribe();

    this.setupFormSubscriptions();
  }

  initializeData() {
    if (this.researchId) {
      this.getResearchById();
    } else {
      this.initializeForm();
      this.isFormInitialized = true;
    }
    this.getOrders();
    this.getServices();
    this.getStatuses();
    this.getDoctors();
    this.getSubcontractors();
  }

  getResearchById = (): void => {
    this.loading = true;
    if (this.researchId) {
      this.researchService.getById(this.researchId).subscribe({
        next: (response: Research) => {
          this.research = response;
          this.initializeForm();
          this.loading = false;
          this.isFormInitialized = true;
        },
        error: () => {
          this.loading = false;
        },
      });
    }
  };

  getStatuses = (): void => {
    this.dataService.getResearchStatuses().subscribe({
      next: (response: Status[]) => {
        this.statuses = response;
      },
      error: () => {
        this.loading = false;
      }
    })
  };

  getServices = (): void => {
    this.dataService.getServices(this.filters).subscribe({
      next: (response: ServicesResponse) => {
        this.services = response.data.map(service => {
          service.fullName = renderServiceName(service);
          return service;
        });
      },
      error: () => {
        this.loading = false;
      }
    })
  };

  getOrders = (): void => {
    this.dataService.getOrders(this.filters).subscribe({
      next: (response: OrdersResponse) => {
        this.orders = response.data.map(order => {
          order.fullName = renderOrderName(order);
          return order;
        });
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  getDoctors = (): void => {
    this.dataService.getDoctorsFromUsers(this.filters).subscribe({
      next: (response: UsersResponse) => {
        this.doctors = response.data.map(doctor => {
          doctor.fullName = renderDoctorName(doctor);
          return doctor;
        });
      },
      error: () => {
        this.loading = false;
      }
    });
  };

  getSubcontractors = (): void => {
    const filters = {
      ...this.filters,
      isPerformer: true,
    }
    this.dataService.getCounterParties(filters).subscribe({
      next: (response: CounterpartiesResponse) => {
        this.subcontractors = response.data
      },
      error: () => {
        this.loading = false;
      }
    })
  };

  getSamples = (): void => {
    const typeId = this.selectOrder?.type?.id ?? this.research?.order?.type?.id;
    const filters = typeId
    ? {
        ...this.filtersWithTypeId,
        typeId,
      }
    : this.filtersWithTypeId;

    this.dataService.getSamples(filters).subscribe({
      next: (response: SamplesResponse) => {
        this.samples = response.data.map(sample => {
          sample.fullName = renderSampleName(sample);
          return sample;
        });
      }
    })
  };

  getSampleById = (id: number) => {
    this.dataService.getSampleById(id).subscribe({
      next: (response: Sample ): void => {
        this.sample = response;
      },
      error: (): void => {}
    });
  }

  getAnimals = (): void => {
    this.dataService.getAnimals(this.filters).subscribe({
      next: (response: AnimalsResponse): void => {
        this.mothers = [];
        this.fathers = [];
        Object.values(response.data).forEach((item: Animal) => {
          const name = `${item.name ?? ''} (${item.inventoryNumber})`;
          if (item.gender === 'Female') {
            this.mothers.push({ ...item, name });
          } else {
            this.fathers.push({ ...item, name });
          }
        });
      },
      error: (): void => {
      }
    });
  }

  initializeForm(): void {
    this.form = new FormGroup({
      status: new FormControl(this.research?.status ?? defaultResearchStatus, Validators.required),
      service: new FormControl({...this.research?.service, fullName: (this.research?.service ? renderServiceName(this.research.service) : null)}, Validators.required),
      sample: new FormControl(this.research?.sample, Validators.required),
      mothers: new FormControl(this.research?.sample.potentialMothers),
      fathers: new FormControl(this.research?.sample.potentialFathers),
      order: new FormControl({...this.research?.order, fullName: (this.research?.order ? renderOrderName(this.research.order) : null)}),
      interDoctor: new FormControl({...this.research?.interDoctor, fullName: (this.research?.interDoctor ? renderDoctorName(this.research.interDoctor) : null)}),
      subcontractor: new FormControl(this.research?.subContragentModel),
      deadline: new FormControl(convertFormat(this.research?.deadline)),
      admissionOfficeDate: new FormControl(convertFormat(this.research?.sample?.admissionOfficeDate)),
      dateOfInter: new FormControl(convertFormat(this.research?.dateOfInter), [dateValidator()]),
      dateOfGettingResult: new FormControl(convertFormat(this.research?.dateOfGettingResult), [dateValidator()]),
      dateOfIssue: new FormControl(convertFormat(this.research?.dateOfIssue), [dateValidator()]),
      dateOfStartIssue: new FormControl(convertFormat(this.research?.dateOfStartIssue), [dateValidator()]),
      result: new FormControl(this.research?.result),
      isDeleted: new FormControl(this.research?.isDeleted ?? false),
      files: new FormControl(this.files ?? []),
      currency: new FormControl(this.research?.currency ? currencies.find((item: Currency): boolean => item.name === this.research?.currency) : defaultCurrency),
      invoiceNumber: new FormControl(this.research?.invoiceNumber),
      dateOfInvoice: new FormControl(convertFormat(this.research?.dateOfInvoice), [dateValidator()]),
      dateOfShipment: new FormControl(convertFormat(this.research?.dateOfShipment), [dateValidator()]),
      invoiceAmount: new FormControl(this.research?.invoiceAmount),
      sumPerSample: new FormControl(this.research?.sumPerSample),
    })

    let previousValue: any;

    const orderControl = this.form.get('order');
    const sampleControl = this.form.get('sample');
    const typeControl = this.form.get('order');

    if (typeControl?.value?.type?.id === OrderTypes.ANIMAL) {
        this.getAnimals();
      }

    orderControl?.value?.id ? sampleControl?.enable() : sampleControl?.disable();

    if (sampleControl?.value) {
      this.getSampleById(sampleControl?.value.id);
    }

    this.filtersWithTypeId.typeId = orderControl?.value?.type?.id;

    sampleControl?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((sample) => {
        this.sample = sample;
      });

    orderControl?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value !== previousValue) {
          sampleControl?.setValue(null);
          this.filtersWithTypeId.typeId = value?.type?.id;
          value?.id ? sampleControl?.enable() : sampleControl?.disable();
          previousValue = value;
          this.getSamples();
          this.getAnimals();
        }
    });
  }



  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupFormSubscriptions(): void {
    const searchSubjects = {
      'order': this.searchOrderSubject$,
      'sample': this.searchSampleSubject$,
      'doctor': this.searchDoctorSubject$,
      'mother': this.searchMothersSubject$,
      'father': this.searchFathersSubject$,
      'service': this.searchServiceSubject$,
      'subcontractor': this.searchSubcontractorsSubject$,
    };

    const subscriptions = Object.entries(searchSubjects).map(([key, subject$]) => {
      return subject$
        .pipe(
          debounceTime(900),
          switchMap(searchTerm => this.loadDataByKey(key, searchTerm)),
          catchError(error => {
            console.error(error);
            return EMPTY;
          }),
          takeUntil(this.destroy$),
          finalize(() => this.loading = false)
        )
        .subscribe();
    });

    this.destroy$.subscribe(() => {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    });
  }

  private loadDataByKey(key: string, searchTerm: string): Observable<any> {
    switch (key) {
      case 'order':
        return this.dataService.getOrders(searchTerm ? { ...this.filters, search: searchTerm } : this.filters).pipe(
          map((response: OrdersResponse) => {
            const searchOrders = response.data.map((order: any) => {
              order.fullName = renderOrderName(order);
              return order;
            });
            this.orders = searchOrders;
            return searchOrders;
          })
        );
      case 'service':
        return this.dataService.getServices(searchTerm ? { ...this.filters, search: searchTerm } : this.filters).pipe(
          map((response: ServicesResponse) => {
            const searchServices = response.data.map((service: any) => {
              service.fullName = renderServiceName(service);
              return service;
            });
            this.services = searchServices;
            return searchServices;
          })
        );
      case 'sample':
        return this.dataService.getSamples(searchTerm ? {...this.filtersWithTypeId, search: searchTerm} : this.filtersWithTypeId).pipe(
          map((response: SamplesResponse) => {
            const searchSamples = response.data.map((sample: any) => {
              sample.fullName = renderSampleName(sample);
              return sample;
            });
            this.samples = searchSamples;
            return searchSamples;
          })
        );
      case 'doctor':
        return this.dataService.getDoctorsFromUsers(searchTerm ? {...this.filters, search: searchTerm } : this.filters).pipe(
          map((response: UsersResponse) => {
            const searchDoctors = response.data.map((doctor: any) => {
              doctor.fullName = renderDoctorName(doctor);
              return doctor;
            });
            return searchDoctors;
          })
        );
      case'subcontractor':
        return this.dataService.getCounterParties(searchTerm ? {...this.filters, search: searchTerm } : this.filters).pipe(
          map((response: CounterpartiesResponse) => {
            this.subcontractors = response.data
          })
        )
      case 'mother':
        return this.dataService.getAnimals(searchTerm ? { ...this.filters, search: searchTerm } : this.filters)
          .pipe(
            map((response: AnimalsResponse) => {
              this.mothers = response.data.filter((item: Animal) => item.gender === 'Female');
            })
          );
      case 'father':
        return this.dataService.getAnimals(searchTerm ? { ...this.filters, search: searchTerm } : this.filters)
          .pipe(
            map((response: AnimalsResponse) => {
              this.fathers = response.data.filter((item: Animal) => item.gender === 'Male');
            })
          );
      default:
        console.warn(`Неизвестный ключ поиска: ${key}`);
        return EMPTY;
    }
  }

  private handleSaveButtonLogic(): Observable<any> {
    const data = this.prepareResearchData();
    const saveObservable = this.getSaveObservable(data);

    return saveObservable.pipe(
      switchMap(() => this.handleSaveSuccess()),
      catchError(() => {
        this.handleSaveError();
        return EMPTY;
      })
    );
  }

  handleSaveButton(): void {
    this.saveButtonClick$.next();
  }

  handleCloseButton(): void {
    this.closeButtonClick$.next();
    this.research = null;
    this.selectResearch = null;
    this.researchId = undefined;
    this.isOpen = false;
  }

  handleCreateSampleEvent(data: Sample): void {
    this.createSampleEvent$.next(data);
  }

  handleCloseSample(): void {
    this.sample =  this.form.get('sample')?.value;
    this.isOpenSampleModal = false;
  }

  addOrUpdateSampleHandler = (isAdd: boolean): void => {
    this.sample = isAdd ? null : this.sample;
    this.orderType = this.research?.order?.type ?? this.form.value?.order?.type ?? null;
    this.isOpenSampleModal = true;
  }

  handleCloseAnimal = (): void => {
    this.closeAnimalModal$.next();
  }

  addAnimalHandler = (input: 'mothers' | 'fathers'): void => {
    this.openAnimalModal$.next(input);
  }

  handleCreateAnimalEvent(event: { animal: Animal, input: 'mothers' | 'fathers' }): void {
    this.createAnimalEvent$.next(event);
  }

  addOrUpdateAnimal = (animal: Animal): void => {
    this.updateAnimalEvent$.next(animal);
  }

  private prepareResearchData(): ResearchInput {
    return {
      result: this.form.value.result,
      orderId: this.form.value.order.id,
      sampleId: this.form.value.sample.id,
      statusId: this.form.value.status.id,
      serviceId: this.form.value.service.id,
      interUserId: this.form.value.interDoctor?.id,
      subcontractorId: this.form.value.subcontractor?.id,
      deadline: this.formatDate(this.form.value.deadline),
      dateOfInter: this.formatDate(this.form.value.dateOfInter),
      dateOfIssue: this.formatDate(this.form.value.dateOfIssue),
      dateOfStartIssue: this.formatDate(this.form.value.dateOfStartIssue),
      dateOfGettingResult: this.formatDate(this.form.value.dateOfGettingResult),
      potentialMothersIds: this.form.value.mothers?.map((item: Animal) => item.animalId ?? item.id),
      potentialFathersIds: this.form.value.fathers?.map((item: Animal) => item.animalId ?? item.id),
      isDeleted: this.form.value.isDeleted,
      currency: this.form.value.currency?.name,
      invoiceNumber: this.form.value.invoiceNumber,
      dateOfInvoice: this.formatDate(this.form.value.dateOfInvoice),
      dateOfShipment: this.formatDate(this.form.value.dateOfShipment),
      invoiceAmount: this.form.value.invoiceAmount,
      sumPerSample: this.form.value.sumPerSample,
      ...(this.research ? { id: this.research?.id ?? null } : {})
    };
  }

  private formatDate(date: string | null): string | null {
    return date ? `${date.split('.').reverse().join('-')}T00:00:00.000Z` : null;
  }

  private getSaveObservable(data: ResearchInput): Observable<any> {
    if (this.research && this.entity) {
      return this.researchService.update(data).pipe(
        switchMap((research: Research) => {
          this.research = research;
          this.handleUpdateEvents(research);
          return this.documentService.create(this.entity!.id, this.research!.id, this.formData);
        })
      );
    } else {
      return this.researchService.create(data).pipe(
        switchMap((research: Research) => {
          this.research = research;
          this.handleUpdateEvents(research);
          return this.documentService.create(this.entity!.id, research.id, this.formData);
        })
      );
    }
  }

  private handleUpdateEvents(research: Research): void {
    this.addOrUpdateResearch?.(research);
    this.addOrUpdateItem?.(this.researches, research);
  }

  private async handleSaveSuccess(): Promise<void> {
    this.formData = new FormData();
    this.loading = false;
    this.handleCloseButton();
  }

  private handleSaveError(): void {
    this.loading = false;
  }

}
