import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { getHttpParams, getHeadersOption } from "@shared/helpers/headers";

import { Kind, KindsFilters, KindInput, KindsResponse } from "@shared/models/kind";
import {  Breed, BreedsResponse, BreedInput, BreedsFilters } from "@shared/models/breed";
import { Biomat, BiomatsFilters, BiomatInput, BiomatsResponse } from "@shared/models/biomat";
import {  ConclusionTypesResponse, Service, ServiceFilters, ServiceInput, ServicesResponse } from "@shared/models/service";
import { NationalitiesFilters, NationalitiesResponse, Nationality, NationalityInput } from '@shared/models/nationality';
import { Oligonucleotide, OligonucleotideInput, OligonucleotidesFilters, OligonucleotidesResponse } from '@shared/models/oligonucleotides';

@Injectable({
  providedIn: 'root'
})
export class DictionariesService {

  constructor(private http: HttpClient) {
  }

  // BIOMATS
  getBiomats(filters: BiomatsFilters): Observable<BiomatsResponse> {
    return this.http.get<BiomatsResponse>(environment.apiUrl + `/api/biomats`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  createBiomat(data: BiomatInput): Observable<Biomat> {
    return this.http.post<Biomat>(environment.apiUrl + `/api/biomats`, data, {
      headers: getHeadersOption()
    });
  }

  updateBiomat(data: BiomatInput): Observable<Biomat> {
    return this.http.patch<Biomat>(environment.apiUrl + `/api/biomats`, data, {
      headers: getHeadersOption()
    });
  }

  deleteBiomat(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/biomats/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restoreBiomat(id: number): Observable<null> {
    return this.http.patch<null>(environment.apiUrl + `/api/biomats/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }

  // BREEDS
  getBreeds(filters: BreedsFilters): Observable<BreedsResponse> {
    return this.http.get<BreedsResponse>(environment.apiUrl + `/api/breeds`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  getBreed(id: number): Observable<Breed> {
    return this.http.get<Breed>(environment.apiUrl + `/api/breeds/${id}`, {
      headers: getHeadersOption()
    });
  }

  createBreed(data: BreedInput): Observable<Breed> {
    return this.http.post<Breed>(environment.apiUrl + `/api/breeds`, data, {
      headers: getHeadersOption()
    });
  }

  updateBreed(data: BreedInput): Observable<Breed> {
    return this.http.patch<Breed>(environment.apiUrl + `/api/breeds`, data, {
      headers: getHeadersOption()
    });
  }

  deleteBreed(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/breeds/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restoreBreed(id: number): Observable<null> {
    return this.http.patch<null>(environment.apiUrl + `/api/breeds/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }

  // KINDS
  getKinds(filters: KindsFilters): Observable<KindsResponse> {
    return this.http.get<KindsResponse>(environment.apiUrl + `/api/kinds`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  createKind(data: KindInput): Observable<Kind> {
    return this.http.post<Kind>(environment.apiUrl + `/api/kinds`, data, {
      headers: getHeadersOption()
    });
  }

  updateKind(data: KindInput): Observable<Kind> {
    return this.http.patch<Kind>(environment.apiUrl + `/api/kinds`, data, {
      headers: getHeadersOption()
    });
  }

  deleteKind(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/kinds/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restoreKind(id: number): Observable<null> {
    return this.http.patch<null>(environment.apiUrl + `/api/kinds/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }

  // SERVICES (услуги)
  getServices(filters: ServiceFilters): Observable<ServicesResponse> {
    return this.http.get<ServicesResponse>(environment.apiUrl + `/api/services`, {
      headers: getHeadersOption(),
      params: getHttpParams(filters)
    });
  }

  getServiceById(id: number): Observable<Service> {
    return this.http.get<Service>(environment.apiUrl + `/api/services/${id}`, {
      headers: getHeadersOption(),
    });
  }

  getConclusionTypes(): Observable<ConclusionTypesResponse> {
    return this.http.get<ConclusionTypesResponse>(environment.apiUrl + `/api/services/conclusion/types`, {
      headers: getHeadersOption()
    });
  }

  deleteConclusionTypes(id: number, ids: number[]): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/services/${id}/conclusion/types`, {
      headers: getHeadersOption(),
      body: {ids}
    });
  }

  createService(data: ServiceInput): Observable<Service> {
    return this.http.post<Service>(environment.apiUrl + `/api/services`, data, {
      headers: getHeadersOption()
    });
  }

  updateService(data: ServiceInput): Observable<Service> {
    return this.http.patch<Service>(environment.apiUrl + `/api/services`, data, {
      headers: getHeadersOption()
    });
  }

  deleteService(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + `/api/services/${id}/delete/soft`, {
      headers: getHeadersOption()
    });
  }

  restoreService(id: number): Observable<null> {
    return this.http.patch<null>(environment.apiUrl + `/api/services/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }

  // Oligonucleotides
    getOligonucleotides(filters: OligonucleotidesFilters): Observable<OligonucleotidesResponse> {
      return this.http.get<OligonucleotidesResponse>(environment.apiUrl + `/api/oligonucleotides`, {
        headers: getHeadersOption(),
        params: getHttpParams(filters)
      });
    }

  getOligonucleotide(id: number): Observable<Oligonucleotide> {
    return this.http.get<Oligonucleotide>(environment.apiUrl + `/api/oligonucleotides/${id}`, {
      headers: getHeadersOption()
    });
  }

  createOligonucleotide(data: OligonucleotideInput): Observable<Oligonucleotide> {
    return this.http.post<Oligonucleotide>(environment.apiUrl + `/api/oligonucleotides`, data, {
      headers: getHeadersOption()
    });
  }

  updateOligonucleotide(data: OligonucleotideInput): Observable<Oligonucleotide> {
    return this.http.patch<Oligonucleotide>(environment.apiUrl + `/api/oligonucleotides`, data, {
      headers: getHeadersOption()
    });
  }

  deleteOligonucleotide(id: number): Observable<any> {
    return this.http.patch<any>(environment.apiUrl + `/api/oligonucleotides/${id}/delete/soft`, {}, {
      headers: getHeadersOption()
    });
  }

  restoreOligonucleotide(id: number): Observable<null> {
    return this.http.patch<null>(environment.apiUrl + `/api/oligonucleotides/${id}/restore`, {}, {
      headers: getHeadersOption()
    });
  }

// NATIONALITIES
getNationalities(filters: NationalitiesFilters): Observable<NationalitiesResponse> {
  return this.http.get<NationalitiesResponse>(environment.apiUrl + `/api/nationalities`, {
    headers: getHeadersOption(),
    params: getHttpParams(filters)
  });
}

createNationality(data: NationalityInput): Observable<Nationality> {
  return this.http.post<Nationality>(environment.apiUrl + `/api/nationalities`, data, {
    headers: getHeadersOption()
  });
}

updateNationality(data: NationalityInput): Observable<Nationality> {
  return this.http.patch<Nationality>(environment.apiUrl + `/api/nationalities`, data, {
    headers: getHeadersOption()
  });
}

deleteNationality(id: number): Observable<any> {
  return this.http.delete<any>(environment.apiUrl + `/api/nationalities/${id}/delete/soft`, {
    headers: getHeadersOption()
  });
}

restoreNationality(id: number): Observable<null> {
  return this.http.patch<null>(environment.apiUrl + `/api/nationalitites/${id}/restore`, {}, {
    headers: getHeadersOption()
  });
}



}
