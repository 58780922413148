import { Input, Component } from "@angular/core";
import localeRu from '@angular/common/locales/ru';
import { CommonModule, registerLocaleData } from "@angular/common";
import { FormArray, FormControl, FormGroup, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { catchError, debounceTime, EMPTY, finalize, map, Observable, Subject, switchMap, takeUntil, tap} from "rxjs";
import { ClarityModule, ClrComboboxModule, ClrCommonFormsModule, ClrIconModule, ClrModalModule } from "@clr/angular";

import { Status } from "@shared/models/status";
import { Filter } from "@shared/models/filter";
import { Entity } from "@shared/models/entity";
import { Document } from "@shared/models/document";
import { Paginate } from "@shared/models/paginate";
import { getLocalityName, Locality } from "@shared/models/gar";
import { noOptions, OptionGarItem } from "@shared/models/option";
import { DataService } from "@shared/helpers/bitrix.data.service";
import { DataService as GetDataService} from "@core/services/data.service";
import { ItemArrType, ItemType } from "@shared/models/addOrUpdate";
import { Doctor, DoctorsResponse, renderDoctorName } from "@shared/models/doctor";
import { renderServiceName, Service, ServicesResponse } from "@shared/models/service";
import { Animal, AnimalsResponse, renderParentName } from "@shared/models/animal";
import { Sample, SamplesResponse, renderSampleName } from "@shared/models/sample";
import { defaultResearchStatus, Research, ResearchInput } from "@shared/models/research";
import { Contract, ContractsResponse, renderContractName } from "@shared/models/contract";
import { Order, OrderFilters, OrderInput, OrderType, OrderTypes } from "@shared/models/order";
import { renderSubdivisionName, Subdivision, SubdivisionsResponse } from "@shared/models/subdivision";


import { convertFormat, dateValidator } from "@shared/helpers/date";

import { DictionariesService } from "../../../admin/services/dictionaries.service";

import { SampleComponent } from "../../../samples/components/sample/sample.component";
import { ResearchComponent } from "../../../researches/components/research/research.component";
import {ContractComponent} from "../../../contracts/components/contract/contract.component";
import {DoctorComponent} from "../../../admin/components/registers/doctors/doctor/doctor.component";
import { AnimalComponent } from "../../../admin/components/registers/animals/animal/animal.component";

import { GarService } from "@core/services/gar.service";
import { DocumentService } from "@core/services/document.service";

import { ButtonComponent } from "@core/components/button/button.component";
import { BitrixComponent } from "../../../bitrix/bitrix/bitrix.component";
import { SelectComponent } from "@core/components/input/select/select.component";
import { UploadDocumentComponent } from "@core/components/documents/document.component";
import { TextFieldComponent } from "@core/components/input/text-field/text-field.component";
import { DatepickerComponent } from "@core/components/input/date-picker/datepicker.component";
import { AutocompleteComponent } from "@core/components/input/autocomplete/autocomplete.component";
import { MultipleAutocompleteComponent } from "@core/components/input/multiple-autocomplete/multiple-autocomplete.component";
import { Router } from "@angular/router";
import { SharedService } from "@core/services/sharedService";
import { OrderService } from "@modules/orders/services/order.service";

registerLocaleData(localeRu);

interface ApiResponse<T> {
  data: Record<string, T>;
}

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    ClrIconModule,
    ClarityModule,
    ClrModalModule,
    ClrComboboxModule,
    ReactiveFormsModule,
    ClrCommonFormsModule,
    BitrixComponent,
    AnimalComponent,
    DoctorComponent,
    SampleComponent,
    SelectComponent,
    ButtonComponent,
    ContractComponent,
    ResearchComponent,
    TextFieldComponent,
    DatepickerComponent,
    AutocompleteComponent,
    UploadDocumentComponent,
    MultipleAutocompleteComponent
  ],
  standalone: true
})

export class OrderComponent {
  @Input() orders: Order[];
  @Input() handleClose: () => void;
  @Input() addOrUpdateOrder?: (order: Order) => void;
  @Input() addOrUpdateItem?: (items: ItemArrType, item: ItemType) => void;
  @Input() selectOrder: Order | null;

  loading: boolean = false;
  entity: Entity | undefined;
  formData: FormData = new FormData();

  postData: any;

  error: any = null;
  isOpen: boolean = true;
  form: FormGroup;

  filters:Filter = {
    isDeleted: false,
    limit: 10,
  }

  files: Document[];
  types: OrderType[];
  services: Service[];

  animal: Animal;
  mothers: Animal[];
  fathers: Animal[];

  isCopy: boolean;
  sample: Sample;
  samples: Sample[];

  doctor: Doctor | null;
  doctors: Doctor[];
  orderStatuses: Status[];

  contract: Contract | null;
  contracts: Contract[];
  researchStatuses: Status[];
  typesSubdivision: Subdivision[];
  localities: Locality[] | OptionGarItem[] = noOptions;

  researchFilters: OrderFilters = {
    limit: 5,
    offset: 0
  };

  filtersWithTypeId: Filter = {
    isDeleted: false,
    limit: 10
  }

  paginate: Paginate = {
    total: 0,
    totalPages: 0,
  };

  typeId: number;
  orderName: string;
  orderType: OrderType | null;
  currentNumber: number;
  currentPage: number = 1;
  currentAnimalIndex: number;
  currentAnimalInput: 'mothers' | 'fathers';

  isOpenSampleModal: boolean = false;
  isOpenAnimalModal: boolean = false;
  isOpenDoctorModal: boolean = false;
  isOpenResearchModal: boolean = false;
  isOpenContractModal: boolean = false;

  protected readonly renderSubdivisionName = renderSubdivisionName;
  protected readonly renderContractName = renderContractName;
  protected readonly renderServiceName = renderServiceName;
  protected readonly renderDoctorName = renderDoctorName;
  protected readonly renderParentName = renderParentName;
  protected readonly renderSampleName = renderSampleName;
  protected readonly getLocalityName = getLocalityName;
  protected readonly OrderTypes = OrderTypes;

  private searchSubdivisionSubject$ = new Subject<string>();
  private searchContractSubject$ = new Subject<string>();
  private searchDoctorsSubject$ = new Subject<string>();
  private searchSamplesSubject$ = new Subject<string>();
  private searchServiceSubject$ = new Subject<string>();
  private searchMothersSubject$ = new Subject<string>();
  private searchFathersSubject$ = new Subject<string>();
  private searchLocalitySubject$ = new Subject<string>();

  private readonly destroy$ = new Subject<void>();

  private filesChange$ = new Subject<Document[]>();
  private formDataChange$ = new Subject<FormData>();
  private entityChange$ = new Subject<Entity | undefined>();

  private saveButtonClick$ = new Subject<void>();
  private closeButtonClick$ = new Subject<void>();
      
  private closeAnimalModal$ = new Subject<void>();
  private updateAnimalEvent$ = new Subject<Animal>();
  private openAnimalModal$ = new Subject<'mothers' | 'fathers'>();
  private createAnimalEvent$ = new Subject<{ animal: Animal, input: 'mothers' | 'fathers' }>();

  constructor(private router: Router,
              private garService: GarService,
              private orderService: OrderService,
              private documentService: DocumentService,
              private dictionariesService: DictionariesService,
              private dataService: DataService,
              private getDataService: GetDataService,
              private sharedService: SharedService,
              private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {    
    this.initializeForm();
    this.setupFormSubscriptions();
    this.initializeDataStreams();

    this.filesChange$
    .pipe(
      tap(files => this.files = files)
    )
    .subscribe();

  this.formDataChange$
    .pipe(
      tap(formData => this.formData = formData)
    )
    .subscribe();

  this.entityChange$
    .pipe(
      tap(entity => this.entity = entity)
    )
    .subscribe();

    this.openAnimalModal$
    .pipe(
      tap(input => {
        this.currentAnimalInput = input;
        this.isOpenAnimalModal = true;
      })
    )
    .subscribe();

  this.closeAnimalModal$
    .pipe(
      tap(() => this.isOpenAnimalModal = false)
    )
    .subscribe();

  this.createAnimalEvent$
    .pipe(
      tap(event => {
        if (event) {
          const control = this.form.get(event.input);
          const currentValues = control?.value ?? [];
          control?.setValue([...currentValues, event.animal]);
        }
      })
    )
    .subscribe();

    this.updateAnimalEvent$
    .pipe(
      tap(animal => {
        const targetArray = (animal?.gender.toLowerCase() === 'female') ? this.mothers : this.fathers;
        const index: number = targetArray.findIndex((item: Animal): boolean => item.id === animal.id);

        if (index === -1) {
          targetArray.unshift(animal);
        } else {
          targetArray[index] = animal;
        }
      })
    )
    .subscribe();

    this.closeButtonClick$
    .pipe(
      tap(() => {
        this.handleClose();
        this.selectOrder = null;
        this.isOpen = false;
      })
    )
    .subscribe();

    this.saveButtonClick$
    .pipe(
      switchMap(() => this.handleSaveButtonLogic()),
      catchError(error => {
        console.error(error);
        return EMPTY;
      })
    )
    .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      number: [this.selectOrder?.number],
      amount: [this.selectOrder?.amount],
      status: [this.selectOrder?.status],
      type: [this.selectOrder?.type],
      addictionNumber: [this.selectOrder?.addictionNumber],
      deadline: [convertFormat(this.selectOrder?.deadline), [dateValidator()]],
      dateOfSamplesArrived: [convertFormat(this.selectOrder?.dateOfSamplesArrived), [dateValidator()]],
      contract: [this.selectOrder?.contract],
      researches: this.fb.array([]),
      isDeleted: [this.selectOrder?.isDeleted ?? false],
      files: [this.files ?? []],

      comment: [this.selectOrder?.comment],
      locality: [this.selectOrder?.locality],
      paidSum: [this.selectOrder?.paidSum],
      dateOfPayment: [convertFormat(this.selectOrder?.dateOfPayment), [dateValidator()]],
      subdivision: [this.selectOrder?.typeSubdivision],
      doctor: [{...this.selectOrder?.doctor, fullName: (this.selectOrder?.doctor ? renderDoctorName(this.selectOrder.doctor) : null)}],
      diagnosis: [this.selectOrder?.diagnosis]
    });

    this.contract = this.form.get('contract')?.value;

    this.form.get('contract')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((contract) => {
      this.contract = contract
    })

    this.doctor = this.form.get('doctor')?.value;
    
    this.form.get('doctor')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((doctor) => {
      this.doctor = doctor
    })
    
    this.form.get('type')?.value ? this.form.get('subdivision')?.enable() : this.form.get('subdivision')?.disable();

    let previousValue: any;

    this.form.get('type')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== previousValue) {
        const typeSubdivisionControl = this.form.get('subdivision');
        const researchesArray = this.form.get('researches') as FormArray;
        this.filtersWithTypeId.typeId = value?.id;

        if (value?.id) {
          typeSubdivisionControl?.enable();
          researchesArray.controls.forEach((control) => {     
            if (control instanceof FormGroup) {
              control.get('sample')?.enable();
            }
          });
          if (value?.id === OrderTypes.ANIMAL) {
            this.getAnimals();
            this.form.get('doctor')?.setValue(null);
            this.form.get('diagnosis')?.setValue(null);
          }
          if (value.name !== this.selectOrder?.type.name) {
            typeSubdivisionControl?.setValue(null);
          }
          this.getSubdivisionsByTypeId();
          this.getSamples();
        } else {
          typeSubdivisionControl?.disable();
          researchesArray.controls.forEach((control) => {     
            if (control instanceof FormGroup) {
              control.get('sample')?.disable();
            }
          });
        }
        previousValue = value;
      }
    });

  }

  private initializeDataStreams(): void {
    const typeControl = this.form.get('type')?.value
    this.getOrderStatuses();
    this.getOrderTypes();
    this.getContracts();
    if (!this.selectOrder) {
      this.getServices();
      this.getResearchStatuses()
      if (typeControl?.id ===  OrderTypes.ANIMAL) {
        this.getAnimals();
      }
    }
    if (typeControl?.id !==  OrderTypes.ANIMAL) {
      this.getDoctors();
    }
    if (typeControl?.id !==  OrderTypes.ANIMAL) {
      this.getSubdivisionsByTypeId();
    }
  }

  private setupFormSubscriptions(): void {
    this.dataService.postData$.subscribe((data) => {
      if (data) {
        this.postData = data;
        this.updateForm();
      }
    });
    const searchSubjects = {
      'mother': this.searchMothersSubject$,
      'father': this.searchFathersSubject$,
      'service': this.searchServiceSubject$,
      'sample': this.searchSamplesSubject$,
      'doctor': this.searchDoctorsSubject$,
      'contract': this.searchContractSubject$,
      'localities': this.searchLocalitySubject$,
      'subdivision': this.searchSubdivisionSubject$
    };

    const subscriptions = Object.entries(searchSubjects).map(([key, subject$]) => {
      return subject$
        .pipe(
          debounceTime(900),
          switchMap(searchTerm => this.loadDataByKey(key, searchTerm)),
          catchError(error => {
            console.error(error);
            return EMPTY;
          }),
          takeUntil(this.destroy$),
          finalize(() => this.loading = false)
        )
        .subscribe();
    });

    this.destroy$.subscribe(() => {
      subscriptions.forEach(subscription => subscription.unsubscribe());
    });
  }

  private updateForm(): void {
    this.form.patchValue({
        number: this.postData?.number,
        amount: this.postData?.amount,
        status: this.postData?.status,
        addictionNumber: this.postData?.addictionNumber,
        type: this.postData?.type,
        deadline: convertFormat(this.postData?.deadline),
        dateOfSamplesArrived: convertFormat(this.postData?.dateOfSamplesArrived),
        contract: this.postData?.contract,
        researches: this.fb.array([]),
        isDeleted: this.postData?.isDeleted ?? false,
        files: this.files ?? [],
        comment: this.postData?.comment,
        locality: this.postData?.locality,
        paidSum: this.postData?.paidSum,
        dateOfPayment: convertFormat(this.postData?.dateOfPayment),
        subdivision: this.postData?.typeSubdivision,
        doctor: {...this.postData?.doctor, fullName: (this.postData?.doctor ? renderDoctorName(this.postData.doctor) : null)},
        diagnosis: this.postData?.diagnosis
    });
}

private loadDataByKey(key: string, searchTerm: string): Observable<any> {
  switch (key) {
      case 'mother':
        return this.getDataService.getAnimals(searchTerm ? { ...this.filters, search: searchTerm } : this.filters)
          .pipe(
            map((response: AnimalsResponse) => {
              this.mothers = response.data.filter((item: Animal) => item.gender === 'Female');
            })
          );
      case 'father':
        return this.getDataService.getAnimals(searchTerm ? { ...this.filters, search: searchTerm } : this.filters)
          .pipe(
            map((response: AnimalsResponse) => {
              this.fathers = response.data.filter((item: Animal) => item.gender === 'Male');
            })
          );
      case 'service':
        return this.dictionariesService.getServices(searchTerm ? {...this.filters, search: searchTerm } : this.filters).pipe(
          map((response: ServicesResponse) => {
          const searchServices = response.data.map((service: any) => {
            service.fullName = renderServiceName(service);
            return service
        })
        this.services = searchServices;
        return searchServices;
        })
      );
      case 'sample':
        return this.getDataService.getSamples(searchTerm ? {...this.filtersWithTypeId, search: searchTerm} : this.filtersWithTypeId).pipe(
          map((response: SamplesResponse) => {
            const searchSamples = response.data.map((sample: any) => {
              sample.fullName = renderSampleName(sample);
              return sample
          })
          this.samples = searchSamples;
          return searchSamples;
        })
      );
      case 'doctor':
        return this.getDataService.getDoctors(searchTerm ? {...this.filters, search: searchTerm } : this.filters).pipe(
          map((response: DoctorsResponse) => {
          const searchDoctors = response.data.map((doctor: any) => {
            doctor.fullName = renderDoctorName(doctor);
            return doctor
        })
        this.doctors = searchDoctors;
        return searchDoctors;
        })
      );
      case 'contract':
        return this.getDataService.getContracts(searchTerm ? {...this.filters, search: searchTerm } : this.filters).pipe(
          map((response: ContractsResponse) => {
            this.contracts = response.data}));
      case 'localities':
        return this.garService.getLocalities(searchTerm).pipe(
          map((response: Locality[]) => this.localities = response));
      default:
        console.warn(`Неизвестный ключ поиска: ${key}`);
        return EMPTY;
    }
  }

  getAnimals = (): void => {
    this.getDataService.getAnimals(this.filters).subscribe({
      next: (response: AnimalsResponse): void => {
        this.mothers = [];
        this.fathers = [];
        Object.values(response.data).forEach((item: Animal) => {
          const name = `${item.name ?? ''} (${item.inventoryNumber})`;
          if (item.gender === 'Female') {
            this.mothers.push({ ...item, name });
          } else {
            this.fathers.push({ ...item, name });
          }
        });
      },
      error: (): void => {
      }
    });
  }

  getDoctors = (): void => {
    this.getDataService.getDoctors(this.filters).subscribe({
      next: (response: DoctorsResponse) => {
        this.doctors = response.data.map(doctor => {
          doctor.fullName = renderDoctorName(doctor);
          return doctor;
        });
      },
      error: () => {
        this.loading = false;
      }
    });
  };

  getServices = (): void => {
    this.getDataService.getServices(this.filters).subscribe({
      next: (response: ServicesResponse) => {
        this.services = response.data.map(service => {
          service.fullName = renderServiceName(service);
          return service;
        });
      },
      error: () => {
        this.loading = false;
      }
    })
  };

  getResearchStatuses = (): void => {
    this.getDataService.getResearchStatuses().subscribe({
      next: (response: Status[]) => {
        this.researchStatuses = response;
      },
      error: () => {
        this.loading = false;
      }
    })
  };

  getOrderStatuses = (): void => {
    this.getDataService.getOrderStatuses().subscribe({
      next: (response: Status[]) => {
        this.orderStatuses = response;
      },
      error: () => {
        this.loading = false;
      }
    })
  };

  getOrderTypes = (): void => {
    this.getDataService.getOrderTypes().subscribe({
      next: (response: OrderType[]) => {
        this.types = response;
      },
      error: () => {
        this.loading = false;
      }
    })
  };

  getContracts = (): void => {
    this.getDataService.getContracts(this.filters).subscribe({
      next: (response: ContractsResponse) => {
        this.contracts = response.data;
      },
      error: () => {
        this.loading = false;
      }
    })
  }

  getSamples = (): void => {
    const typeId = this.form.get('type')?.value?.id;
    const filters = typeId
    ? {
        ...this.filtersWithTypeId,
        typeId,
      }
    : this.filtersWithTypeId;

    this.getDataService.getSamples(filters).subscribe({
      next: (response: SamplesResponse) => {
        this.samples = response.data.map(sample => {
          sample.fullName = renderSampleName(sample);
          return sample;
        });
      }
    })
  };

  getSubdivisionsByTypeId = (): void => {
    const typeId = this.form.get('type')?.value?.id;
    const filters = typeId
    ? {
        ...this.filtersWithTypeId,
        typeId,
      }
    : this.filtersWithTypeId;

    this.getDataService.getSubdivisionsByTypeId(filters).subscribe({
      next: (response: SubdivisionsResponse) => {
        this.typesSubdivision = response.data;
        },
      error: () => {
        this.loading = false;
      }
      })
  };

  addOrUpdateSampleHandler = (index: number, isAdd: boolean, isCopy: boolean = false ): void => {
    this.isOpenSampleModal = true;

    this.sample = isAdd ? null : (index >= 0 ? ((this.form.get('researches') as FormArray).at(index) as FormGroup).get('sample')?.value : null);
    this.isCopy = isCopy;

    this.currentNumber = index + 1;
    this.orderType = this.selectOrder?.type ?? this.form.get('type')?.value;
    this.orderName = (this.selectOrder?.number || this.form.value.number) ?? ''
  }

  handleCreateSampleEvent(event: { sample: Sample, index: number }): void {
    const fullName = renderSampleName(event.sample);
   ((this.form.get('researches') as FormArray).at(event.index) as FormGroup).get('sample')?.setValue({...event.sample, fullName});
  }

  addAnimalHandler = (index: number, input: 'mothers' | 'fathers'): void => {
    this.currentAnimalIndex = index;
    this.currentAnimalInput = input;
    this.isOpenAnimalModal = true;
  }

  handleCreateAnimalEvent(event: { animal: Animal, index: number, input: 'mothers' | 'fathers' }): void {
    if (event) {
      const controlName = event.input === 'mothers' ? 'mothers' : 'fathers';
      const control = ((this.form.get('researches') as FormArray).at(event.index) as FormGroup).get(controlName);
      const currentValues = control?.value ?? [];
      
      control?.setValue([...currentValues, event.animal]);
    }
  }

  addOrUpdateDoctorHandler = (isAdd: boolean): void => {
    this.doctor = isAdd ? null : this.doctor;
    this.isOpenDoctorModal = true;
  }

  handleCreateDoctorEvent(data: Doctor): void {
    const fullName = renderDoctorName(data)
    this.form.get('doctor')?.setValue({...data, fullName});
  }

  addOrUpdateContractHandler = (isAdd: boolean): void => {
    this.contract = isAdd ? null : this.contract;
    this.isOpenContractModal = true;
  }

  handleCreateContractEvent(data: Contract): void {
    this.form.get('contract')?.setValue(data);
  }

  handleCloseAnimal = (): void => {
    this.isOpenAnimalModal = false;
  }

  handleCloseDoctor = (): void => {
    this.doctor =  this.form.get('doctor')?.value;
    this.isOpenDoctorModal = false;
  }

  handleCloseContract = (): void => {
    this.contract =  this.form.get('contract')?.value;
    this.isOpenContractModal = false;
  }

  handleCloseResearch = (): void => {
    this.isOpenResearchModal = false;
  }

  handleCloseSample(): void {
    this.isOpenSampleModal = false;
  }

  onFilesChange(files: Document[]): void {
    this.filesChange$.next(files);
  }

  onFormDataChange(formData: FormData): void {
    this.formDataChange$.next(formData);
  }

  onEntityChange(entity: Entity | undefined): void {
    this.entityChange$.next(entity);
  }
                              
  onSearchSubdivision = (input: string) => this.searchSubdivisionSubject$.next(input);
  onSearchContract = (input: string) => this.searchContractSubject$.next(input);
  onSearchService = (input: string) => this.searchServiceSubject$.next(input);
  onSearchSample = (input: string) => this.searchSamplesSubject$.next(input);
  onSearchMother = (input: string) => this.searchMothersSubject$.next(input);
  onSearchFather = (input: string) => this.searchFathersSubject$.next(input);
  onSearchDoctor = (input: string) => this.searchDoctorsSubject$.next(input);
  onSearchLocality = (input: string) => this.searchLocalitySubject$.next(input);

  handleCloseButton(): void {
    this.closeButtonClick$.next();
    this.selectOrder = null;
    this.isOpen = false;
  }

  addResearches() {
    this.isOpenResearchModal = true;
  }

  addOrCreateResearches() {
    this.selectOrder ? this.addResearches() : this.createResearchHandler();
  }

  get researches() {
    return this.form.get('researches') as FormArray;
  }

  createResearchHandler = (): void => {
    this.researches.push(this.fb.group({
      services: new FormControl([], Validators.required),
      sample: new FormControl(null, Validators.required),
      status: new FormControl(defaultResearchStatus, Validators.required),
      mothers: new FormControl(null),
      fathers: new FormControl(null),
      isDeleted: new FormControl(false),
    }));

    if (!this.form.get('type')?.value?.id ) {
      const researchesArray = this.form.get('researches') as FormArray;
      researchesArray.controls.forEach((control) => { 
        if (control instanceof FormGroup) {
          control.get('sample')?.disable();
        }
      });
    } else {
      this.getSamples();
    }
  }

  deleteResearch = (index: number): void => {
    if (this.form.value.researches[index]?.id) {
      this.form.value.researches[index].isDeleted = true;
    } else {
      this.researches.removeAt(index);
    }
  }

  transformResearchServiceIds(services: Service[], research: Research) {
    const researchId = research.id;
    const serviceId = research?.services?.[0].id;
    const newService = services.map((item: Service) => ({serviceId: item.id})).filter(obj => obj.serviceId !== serviceId)

    if (researchId) {
      return [{ researchId, serviceId }, ...newService];
    }
    else {
      return research?.services?.map(item => ({serviceId: item.id}) )
    }
  }

  transformServiceIds = (inputObject: Service[]): any => {
    return inputObject.map((item: Service) => item.id)
  }

  getResearchesToSave = (): ResearchInput[] => {
    const data: ResearchInput[] = [];
    this.form.value.researches.forEach((research: Research): void => {
      if (research?.services) {
          data.push({
            id: research?.id ?? undefined,
            sampleId: research.sample.id,
            statusId: research.status.id,
            serviceIds: this.transformServiceIds(research.services),
            researchServiceIds: this.transformResearchServiceIds(research.services, research),
            potentialMothersIds: research.mothers?.map((item: Animal) => item.animalId ?? item.id),
            potentialFathersIds: research.fathers?.map((item: Animal) => item.animalId ?? item.id),
            isDeleted: research.isDeleted
          });
      }
    });
    return data;
  }

  addOrUpdateAnimal = (animal: Animal): void => {
    this.updateAnimalEvent$.next(animal);
  }

  goTo = () => {
    this.router.navigateByUrl(`/researches`);
    this.form.dirty ? this.handleSaveButton() : null;
    this.handleClose();
    this.sendData();
  }
  
  sendData() {
    this.sharedService.updateData(this.selectOrder?.id);
  }


  private formatDate(date: string | null): string | null {
    return date ? `${date.split('.').reverse().join('-')}T00:00:00.000Z` : null;
  }

  private prepareOrderData(): OrderInput {
    return {
      number: this.form.value.number,
      addictionNumber: this.form.value.addictionNumber,
      amount: this.form.value?.amount ? this.form.value?.amount.toString() : null,
      contractId: this.form.value.contract?.id,
      statusId: this.form.value.status?.id,
      deadline: this.formatDate(this.form.value.deadline),
      dateOfSamplesArrived: this.formatDate(this.form.value.dateOfSamplesArrived),
      isDeleted: this.form.value.isDeleted,
      typeSubdivisionId: this.form.value.subdivision?.id,
      typeId: this.form.value.type.id,
      researches: this.getResearchesToSave(),
      comment: this.form.value?.comment,
      diagnosis: this.form.value?.diagnosis,
      doctorId: this.form.value?.doctor?.id,
      paidSum: this.form.value.paidSum || null,
      dateOfPayment:  this.formatDate(this.form.value.dateOfPayment),
      physicalAddressLocalityIdAddrObj: this.form.value?.locality?.idAddrObj,
      ...(this.selectOrder ? {
        id: this.selectOrder?.id ?? null
      } : {}),

    };
  }

  private getSaveObservable(data: OrderInput): Observable<any> {
    if (this.selectOrder && this.entity) {
          delete data.researches;
      return this.orderService.update(data).pipe(
        switchMap((order: Order) => {
          this.selectOrder = order;
          this.handleUpdateEvents(order);
          return this.documentService.create(this.entity!.id, this.selectOrder!.id, this.formData);
        })
      );
    } else {
      return this.orderService.create(data).pipe(
        switchMap((order: Order) => {
          this.selectOrder = order;
          this.handleUpdateEvents(order);
          return this.documentService.create(this.entity!.id, order.id, this.formData);
        })
      );
    }
  }

  private handleUpdateEvents(order: Order): void {
    this.addOrUpdateOrder?.(order);
    this.addOrUpdateItem?.(this.orders, order);
  }

  private async handleSaveSuccess(): Promise<void> {
    this.formData = new FormData();
    this.loading = false;
    this.handleCloseButton();
  }

  private handleSaveError(): void {
    this.loading = false;
  }

  private handleSaveButtonLogic(): Observable<any> {
    const data = this.prepareOrderData();
    const saveObservable = this.getSaveObservable(data);
  
    return saveObservable.pipe(
      switchMap(() => this.handleSaveSuccess()),
      catchError(() => {
        this.handleSaveError();
        return EMPTY;
      })
    );
  }

  handleSaveButton(): void {
    this.saveButtonClick$.next();
  }

}
