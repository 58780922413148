import { Breed } from "./breed";
import { Paginate } from "./paginate";
import { convertFormat } from "@shared/helpers/date";

export interface Animal {
  id: number
  animalId: number
  name: string
  fullName: string
  inventoryNumber: string
  motherId: number
  fatherId: number
  breedId: number
  breed: Breed
  mother?: Animal | null
  father?: Animal | null
  birthDate: string
  gender: string
  isDeleted: boolean
  createdAt: string
}

export interface AnimalInput {
  id?: number | null
  name: string
  inventoryNumber: string
  motherId: number | null
  fatherId: number | null
  breedId: number | null
  birthDate: string | null
  gender: number
  isDeleted: boolean
}

export interface AnimalsFilters {
  search?: string
  orderBy?: string
  isDeleted?: boolean
  offset?: number
  limit?: number
  createdAt?: string
}

export interface AnimalsResponse extends Paginate {
  data: Animal[]
}


export interface Locus {
  id: number
  code: string
}

export interface MicrosatelliteValue {
  id: number
  microsatelliteId: number
  locusId: number
  locus: Locus
  allele1: number
  allele2: number
}

export interface Microsatellite {
  id: number
  animalId: number
  animal: Animal
  values: MicrosatelliteValue[]
}

export interface MicrosatelliteValueInput extends Omit<MicrosatelliteValue, 'id' | 'locus' | 'microsatelliteId'> {
  id?: number
}

export interface MicrosatelliteUpdateInput extends Omit<Microsatellite, 'id' | 'animal' | 'values'> {
  id?: number
  values: MicrosatelliteValueInput[]
}

export interface MicrosatelliteCreateInput extends Omit<MicrosatelliteUpdateInput, 'id'> {
}

export const renderParentName = (parent: any) => `${parent.name ?? ''} (${parent.inventoryNumber ?? ''}) ${convertFormat(parent.birthDate)}`;
