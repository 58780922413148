export interface Currency {
  id: number
  name: string
}

export const defaultCurrency: Currency = {
  id: 1,
  name: 'Рубли'
}

export const currencies: Currency[] = [
  {
    id: 1,
    name: 'Рубли'
  },
  {
    id: 2,
    name: 'Доллары'
  },
  {
    id: 3,
    name: 'Евро'
  },
  {
    id: 4,
    name: 'Юань'
  },
];