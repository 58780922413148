import { CommonModule, registerLocaleData } from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { ClrCheckboxModule, ClrIconModule, ClrModalModule } from "@clr/angular";

import { debounceTime, Subject } from "rxjs";

import { translateToRusGender } from "@constants/gender";
import { translateToEngGender } from "@constants/gender";
import { Gender } from "@constants/gender";
import { GENDERS } from "@constants/gender";
import { AutocompleteComponent } from "@core/components/input/autocomplete/autocomplete.component";
import { DatepickerComponent } from "@core/components/input/date-picker/datepicker.component";

import { ButtonComponent } from "@core/components/button/button.component";
import { SelectComponent } from "@core/components/input/select/select.component";
import { TextFieldComponent } from "@core/components/input/text-field/text-field.component";
import { convertFormat, dateValidator } from "@shared/helpers/date";
import { AnimalsFilters } from "@shared/models/animal";
import { AnimalInput } from "@shared/models/animal";
import { Animal } from "@shared/models/animal";
import { BreedsFilters } from "@shared/models/breed";
import { Breed } from "@shared/models/breed";
import { Kind, KindsFilters } from "@shared/models/kind";
import { AnimalsService } from "@modules/admin/services/animals.service";
import { DictionariesService } from "@modules/admin/services/dictionaries.service";

registerLocaleData(localeRu);

@Component({
  selector: 'app-animal',
  templateUrl: './animal.component.html',
  styleUrls: ['./animal.component.scss'],
  imports: [
    TextFieldComponent,
    ClrIconModule,
    ClrModalModule,
    AutocompleteComponent,
    SelectComponent,
    DatepickerComponent,
    ClrCheckboxModule,
    FormsModule,
    ButtonComponent,
    CommonModule,
    ReactiveFormsModule
  ],
  standalone: true
})

export class AnimalComponent {
  @Input() animals: Animal[];
  @Input() handleClose: () => void;
  @Input() currentAnimalIndex?: number;
  @Input() selectAnimal: Animal | null;
  @Input() currentAnimalInput: 'mothers' | 'fathers';
  @Input() addOrUpdateAnimal?: (animal: Animal) => void;
  @Input() addOrUpdateItem?: (animals: Animal[], animal: Animal) => void;
  @Output() closeButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() createAnimal: EventEmitter<Animal> = new EventEmitter<Animal>();
  @Output() createAnimalWithInput: EventEmitter<{ animal: Animal, input: 'mothers' | 'fathers'}> = new EventEmitter<{ animal: Animal, input: 'mothers' | 'fathers'}>();
  @Output() createAnimalWithIndex: EventEmitter<{ animal: Animal, index: number, input: 'mothers' | 'fathers' }> = new EventEmitter<{ animal: Animal, index: number, input: 'mothers' | 'fathers'}>();

  kinds: Kind[];
  breeds: Breed[];
  male: Animal[] = [];
  female: Animal[] = [];
  isOpen: boolean = true;
  selectedKindId: number | null = null;
  private searchMotherSubject: Subject<string> = new Subject<string>();
  private searchFatherSubject: Subject<string> = new Subject<string>();
  private searchBreedsSubject: Subject<string> = new Subject<string>();
  private searchKindsSubject: Subject<string> = new Subject<string>();
  private readonly debounceTimeMs = 900;
  loadingAnimals: boolean = false;
  loadingBreeds: boolean = false;
  loadingKinds: boolean = false;
  form: FormGroup;
  animalsFilters: AnimalsFilters = {
    limit: 10,
    isDeleted: false
  };
  breedsFilters: BreedsFilters = {
    limit: 10,
    isDeleted: false,
  };
  kindsFilters: KindsFilters = {
    limit: 10,
    isDeleted: false
  };

  constructor(
    private animalService: AnimalsService,
    private dictionariesService: DictionariesService,
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      name: new FormControl(this.selectAnimal?.name),
      inventoryNumber: new FormControl(this.selectAnimal?.inventoryNumber, Validators.required),
      mother: new FormControl(this.selectAnimal?.mother),
      father: new FormControl(this.selectAnimal?.father),
      kind: new FormControl(this.selectAnimal?.breed.kind, Validators.required),
      breed: new FormControl(this.selectAnimal?.breed, Validators.required),
      birthDate: new FormControl(convertFormat(this.selectAnimal?.birthDate), [Validators.required, dateValidator()]),
      gender: new FormControl(this.selectAnimal?.gender ? GENDERS.find((gender: Gender): boolean => gender.name === translateToRusGender(this.selectAnimal?.gender ?? '')) : null, Validators.required),
      isDeleted: new FormControl(this.selectAnimal?.isDeleted ?? false),
    });

    const breedControl = this.form.get('breed');
    breedControl?.disable();

    this.getAnimals();
    this.getKinds();

    this.searchMotherSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchValue: string): void => {
        if (searchValue?.length) {
          this.animalsFilters.search = searchValue;
        } else {
          delete this.animalsFilters.search;
        }
        this.getAnimals();
      });

    this.searchFatherSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchValue: string): void => {
        if (searchValue?.length) {
          this.animalsFilters.search = searchValue;
        } else {
          delete this.animalsFilters.search;
        }
        this.getAnimals();
      });

    this.searchBreedsSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchValue: string): void => {
        if (searchValue?.length) {
          this.breedsFilters.search = searchValue;
        } else {
          delete this.breedsFilters.search;
        }
        this.getBreedsByKind(this.selectedKindId);
      });

    this.searchKindsSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchValue: string): void => {
        if (searchValue?.length) {
          this.kindsFilters.search = searchValue;
        } else {
          delete this.kindsFilters.search;
        }
        this.getKinds();
      });

    this.form.get('kind')?.valueChanges.subscribe((value) => {
      const breedControl = this.form.get('breed');
      if (value) {
        breedControl?.enable();

        this.selectedKindId = value.id;

        if (this.selectedKindId !== null) {
          this.getBreedsByKind(this.selectedKindId);
        }

        breedControl?.setValue(null);
      } else {
        breedControl?.disable();
        breedControl?.setValue(null);
        this.selectedKindId = null;
      }
    });
  }

  getBreedsByKind(kindId: number | null): void {
    if (kindId !== null) {
      this.loadingBreeds = true;
      this.dictionariesService.getBreeds({ ...this.breedsFilters, kindId }).subscribe({
        next: ({ data }): void => {
          this.breeds = Object.values(data);
          this.loadingBreeds = false;
        },
        error: (): void => {
          this.loadingBreeds = false;
        }
      });
    }
  }

  getKinds(): void {
    this.loadingKinds = true;
    this.dictionariesService.getKinds(this.kindsFilters).subscribe({
      next: ({data}): void => {
        this.kinds = Object.values(data);
        this.loadingKinds = false;
      },
      error: (): void => {
        this.loadingKinds = false;
      }
    });
  }

  getAnimals(): void {
    this.loadingAnimals = true;
    this.animalService.get(this.animalsFilters).subscribe({
      next: ({data}): void => {
        this.female = [];
				this.male = [];
				Object.values(data).forEach((item: Animal) => {
          const name = `${item.name ?? ''} (${item.inventoryNumber})`;
					if (item.gender === 'Female') {
						this.female.push({ ...item, name });
					} else {
						this.male.push({ ...item, name });
					}
				});
        this.loadingAnimals = false;
      },
      error: (): void => {
        this.loadingAnimals = false;
      }
    });
  }

  onSearchMother = (input: string) => this.searchMotherSubject.next(input);
  onSearchFather = (input: string) => this.searchFatherSubject.next(input);
  onSearchBreeds = (input: string) => this.searchBreedsSubject.next(input);
  onSearchKinds = (input: string) => this.searchKindsSubject.next(input);

  handleCloseButton(): void {
    this.handleClose();
    this.selectAnimal = null;
    this.isOpen = false;
    this.closeButtonClicked.emit()
  }

  async handleSaveButton(): Promise<void> {
    const data: AnimalInput = {
      name: this.form.value.name && this.form.value.name,
      inventoryNumber: this.form.value.inventoryNumber && this.form.value.inventoryNumber,
      birthDate: this.form.value.birthDate ? `${this.form.value.birthDate.split('.').reverse().join('-')}T00:00:00.000Z` : null,
      gender: this.form.value.gender && translateToEngGender(this.form.value.gender.name),
      isDeleted: this.form.value.isDeleted,
      motherId: (this.form.value.mother && this.form.value.mother.id !== 0) ? this.form.value.mother.id : null,
      fatherId: (this.form.value.father && this.form.value.father.id !== 0) ? this.form.value.father.id : null,
      breedId: this.form.get('breed')?.value.id,
      ...(this.selectAnimal ? {
        id: this.selectAnimal.id
      } : {}),
    };
    (this.selectAnimal ? this.animalService.update(data) : this.animalService.create(data)).subscribe({
      next: (res: Animal): void => {
        if (!this.selectAnimal) {
          this.animalService.updateMicrosatellites({animalId: res.id, values: []}).subscribe({
            next: (): void => {
              this.addOrUpdateItem ? this.addOrUpdateItem(this.animals, res) : null;
              this.addOrUpdateAnimal ? this.addOrUpdateAnimal(res) : null;
              this.handleClose();
              this.currentAnimalIndex !== undefined && this.currentAnimalInput 
                ? this.createAnimalWithIndex?.emit({ animal: res, index: this.currentAnimalIndex, input: this.currentAnimalInput }) 
                : null;
              this.currentAnimalInput !== undefined ? this.createAnimalWithInput?.emit({animal: res, input: this.currentAnimalInput}) : null;
              this.currentAnimalIndex === undefined ? this.createAnimal?.emit(res) : null;
              this.closeButtonClicked.emit()
            },
            error: (): void => {
            }
          });
        } else {
          this.addOrUpdateItem ? this.addOrUpdateItem(this.animals, res) : null;
          this.addOrUpdateAnimal ? this.addOrUpdateAnimal(res) : null;
          this.createAnimal.emit(res);
          this.handleClose();
        }
      },
      error: (): void => {
      }
    });
  }

  protected readonly GENDERS: Gender[] = GENDERS;
}
