<clr-modal [(clrModalOpen)]="isOpen" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <h3 class="modal-title">
    <btn (click)="handleCloseButton()"></btn>
    {{ selectSample ? 'Редактирование' : 'Добавление' }} образца
  </h3>
  <div class="modal-body">
    <form class="horizontal" clrForm [formGroup]="form">
      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10">
          <custom-select key="type"
                        [formGroup]="form"
                        class="custom-combobox"
                        label="Тип заказа (направление)"
                        [options]="orderTypes || (orderType ? [orderType] : [])"
                        [required]="true"
          ></custom-select>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10">
          <text-field type="text"
                      key="number"
                      label="№ образца"
                      class="customInputWidth"
                      [formGroup]="form" [required]="true"
          ></text-field>
        </div>
      </div>
           
      <div *ngIf="form?.value?.type && form?.value?.type.id !== OrderTypes.ANIMAL"
          [ngClass]="{'shown': form.value.type.id !== OrderTypes.ANIMAL && !selectSample}"
      >
        <div class="actionItemsBlock">

          <div style="width:83%">
            <autocomplete key="human"
                          label="Исследуемый"
                          [formGroup]="form"
                          [options]="humans"
                          [loading]="loading"
                          renderKey="fullName"
                          class="custom-combobox"
                          [onInput]="onSearchHuman"
                          [renderOption]="renderHumanName"
                          [required]="form.value?.type?.id !== OrderTypes.ANIMAL"
            ></autocomplete>
          </div>

          <div class="actionBtns">
              <button type="button"
                      aria-label="add"
                      class="btn btn-link addBtn"
                      cds-layout="p-t:xxs m:none m-r:xxs"
                      (click)="addOrEditHumanHandler(true)"
                      ><clr-icon shape="plus"></clr-icon>
              </button>
              <button type="button"
                      aria-label="add"
                      class="btn btn-link addBtn"
                      cds-layout="p-t:xxs m:none"
                      (click)="addOrEditHumanHandler(false)"
                      [disabled]="!human || !human.fullName"
                      ><clr-icon shape="pencil"></clr-icon>
              </button>
          </div>
        </div>
        
        <div class="clr-row clr-align-items-end">
          <div class="clr-col-md-10">
            <custom-select *ngIf="!this.selectSample?.human?.gender" [formGroup]="form" key="gender" label="Пол" [options]="GENDERS"></custom-select>

            <text-field type="text"
                        key="gender_readonly"
                        label="Пол"
                        class="custom-combobox"
                        [formGroup]="form"
                        [readonly]="true"
                        [class]="'custom-text-field'"
                        *ngIf="this.selectSample?.human?.gender"
            ></text-field>
          </div>
        </div>
        <div class="clr-row clr-align-items-end">
          <div class="clr-col-md-10">
            <custom-select [formGroup]="form" key="defGender" label="Пол (определенный)" [options]="GENDERS"></custom-select>
          </div>
        </div>
        <div class="clr-row clr-align-items-end">
          <div class="clr-col-md-10">
            <autocomplete key="oligonucleotide"
                          renderKey="name"
                          [formGroup]="form"
                          [loading]="loading"
                          label="Олигонуклеотид"
                          class="custom-combobox"
                          [options]="oligonucleotides"
                          [onInput]="onSearchOligonucleotide"
            ></autocomplete>
          </div>
        </div>
        <div class="clr-row clr-align-items-end">
          <div class="clr-col-md-10">
            <autocomplete key="defOligonucleotide"
                          renderKey="name"
                          [formGroup]="form"
                          [loading]="loading"
                          class="custom-combobox"
                          [options]="defOligonucleotides"
                          [onInput]="onSearchDefOligonucleotide"
                          label="Олигонуклеотид (определенный)"
            ></autocomplete>
          </div>
        </div>
      </div>

      <div *ngIf="form.value.type?.id === OrderTypes.ANIMAL"
           [ngClass]="{'shown': form.value.type.id === OrderTypes.ANIMAL && !selectSample}"
      >
        <div class="actionItemsBlock">

          <div style="width: 83%">
            <autocomplete key="animal"
                          label="Животное"
                          [formGroup]="form"
                          renderKey="fullName"
                          [options]="animals"
                          class="custom-combobox"
                          [onInput]="onSearchAnimal"
                          [renderOption]="renderParentName"
                          [loading]="loading"
                          [required]="true"
            ></autocomplete>
          </div>

          <div class="actionBtns">
              <button type="button"
                      aria-label="add"
                      class="btn btn-link addBtn"
                      cds-layout="p-t:xxs m:none m-r:xxs"
                      (click)="addOrEditAnimalHandler(true)"
                      ><clr-icon shape="plus"></clr-icon>
              </button>
              <button type="button"
                      aria-label="add"
                      [disabled]="!animal"
                      class="btn btn-link addBtn"
                      cds-layout="p-t:xxs m:none"
                      (click)="addOrEditAnimalHandler(false)"
              ><clr-icon shape="pencil"></clr-icon></button>
          </div>
        </div>
      </div>

      

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10">
          <autocomplete key="biomat"
                        [formGroup]="form"
                        label="Биоматериал"
                        [options]="biomats"
                        class="custom-combobox"
                        [onInput]="onSearchBiomats"
                        [loading]="loading" [required]="true"
          ></autocomplete>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10">
          <custom-select key="status"
                        label="Статус"
                        [formGroup]="form"
                        class="custom-combobox"
                        [options]="statuses" [required]="true"
          ></custom-select>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10">
          <date-picker label="Дата забора" key="drawDate" [formGroup]="form"></date-picker>
        </div>
      </div>

      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10">
          <date-picker label="Дата поступления в офис" key="admissionOfficeDate" [formGroup]="form"></date-picker>
        </div>
      </div>

      <div class="clr-row clr-align-items-end" cds-layout="m-b:md">
        <div class="clr-col-md-10">
          <date-picker label="Дата поступления в лабораторию" key="admissionLabDate" [formGroup]="form"></date-picker>
        </div>
      </div>

      <checkbox label="Хранение" key="isStorage" [formGroup]="form"></checkbox>
      
      <div class="clr-row clr-align-items-end">
        <div class="clr-col-md-10">
          <date-picker *ngIf="form.value?.isStorage"
                   key="shelfLife"
                   [formGroup]="form"
                   label="Срок хранения"
          ></date-picker>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="handleCloseButton()">Отмена</button>
    <button type="button"
            class="btn btn-primary"
            (click)="handleSaveButton()"
            [disabled]="form.status !== 'VALID'"
    >Сохранить</button>
  </div>
</clr-modal>
<ng-container *ngIf="isOpenAnimalModal">
  <app-animal [handleClose]="handleCloseAnimal"
              [animals]="animals"
              [selectAnimal]="animal"
              [addOrUpdateItem]="addOrUpdateItem"
              (closeButtonClicked)="handleCloseAnimal()"
              (createAnimal)="handleCreateAnimalEvent($event)"
  ></app-animal>
</ng-container>
<ng-container *ngIf="isOpenHumanModal">
  <app-human [humans]="humans"
             [selectHuman]="human"
             [handleClose]="handleCloseHuman"
             [addOrUpdateItem]="addOrUpdateItem"
             (closeButtonClicked)="handleCloseHuman()"
             (createHuman)="handleCreateHumanEvent($event)"
  ></app-human>
</ng-container>